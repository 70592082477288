import React, { useRef, useState } from "react";
import { ScrollToTop } from "../../components";
import emailjs from "@emailjs/browser";
import "./contact.css";
import CTAButton from "../../ui/CTAButton";
import { Link } from "react-router-dom";
import { BsEnvelope, BsPhone, BsTelephoneFill } from "react-icons/bs";

const ContactPage = () => {
  const form = useRef();

  const initialState = {
    name: "",
    email: "",
    message: "",
  };

  const [{ name, email, message }, setState] = useState(initialState);

  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    emailjs
      .sendForm(
        "service_kll3w68",
        "template_8zrnskp",
        form.current,
        "1A1gH7OkaK_n2sz-s"
      )
      .then(
        (result) => {
          console.log(result.text, result.status);
          clearState();
          setStatusMessage("Message sent! We'll get back to you shortly.");
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(
            `${error.text} Couldn't send. Check your network connection`
          );
        }
      );
    e.target.reset();
  };
  return (
    <>
      <section className="contactPageSection">
        <div className="container">
          <div className="contactPageHeader">
            <h1>Contact Us</h1>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d92247.07362635227!2d-79.6483877896559!3d43.737121402358056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x882b3a86a828cb2f%3A0x951ee6012c4beab5!2s1111%20Albion%20Rd%2C%20Etobicoke%2C%20ON%20M9V%201A9%2C%20Canada!3m2!1d43.737151!2d-79.5659871!5e0!3m2!1sen!2sgh!4v1702050665595!5m2!1sen!2sgh"
              width="100%"
              height="250"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Address"
            ></iframe>
          </div>
          <div className="addressForm">
            <div className="address">
              <small>
                1111 Albion Rd,
                <br />
                Etobicoke, ON M9V 1A9,
                <br />
                Canada
              </small>
              <br /> <br />
              <small>
                <div className="infoContainer">
                  <BsTelephoneFill className="icon gold" />
                  +1 416-785-0522
                </div>
                <br />
                <div className="infoContainer">
                  <BsEnvelope className="icon gold" />
                  <Link
                    className="blue"
                    to="mailto:info@bansahandassociates.com"
                  >
                    info@bansahandassociates.com
                  </Link>
                </div>
              </small>
            </div>
            <div className="contactForm">
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  name="name"
                  placeholder="Your Full Name"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  required
                />
                <textarea
                  name="message"
                  rows="7"
                  placeholder="Your Message"
                  required
                ></textarea>
                {/* <p className="alertMsg">{statusMessage}</p> */}
                <CTAButton link="/" text="Send" specialClass="blueBkg" />
              </form>
            </div>
          </div>
        </div>
        <div className="redWave" />
      </section>
      <ScrollToTop />
    </>
  );
};

export default ContactPage;
