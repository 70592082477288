import React from "react";
import { Link } from "react-router-dom";
import "./cta-button.css";

const CTAButton = ({ text, link, specialClass, blank = false }) => {
  const buttonClasses = `cta-button ${specialClass}`;
  return blank ? (
    <Link to={link} target="_blank" className="cta-button-wrapper">
      <button className={buttonClasses}>{text}</button>
    </Link>
  ) : (
    <Link to={link} className="cta-button-wrapper">
      <button className={buttonClasses}>{text}</button>
    </Link>
  );
};

export default CTAButton;
