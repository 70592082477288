import React from "react";
import { Link } from "react-router-dom";
import { navigationLinks, ourServiceLinks } from "./../../data";
import { FaSistrix } from "react-icons/fa";
import {
  BsWhatsapp,
  BsFacebook,
  BsInstagram,
  BsEnvelope,
  BsMap,
} from "react-icons/bs";

import logo from "./../../assets/img/landscape.png";
import "./footer.css";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <footer>
        <div className="container">
          <div className="footerNavigation">
            <div className="left">
              <div className="companyDetail">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="description">
                  <p>
                    Bansah and Associates - Navigating Your Path to Canadian
                    Immigration Success.
                  </p>
                </div>
                <div className="address">
                  <div className="infoContainer">
                    <BsMap className="icon gold" />
                    <p>1111 Albion Rd, Etobicoke, ON M9V 1A9, Canada</p>
                  </div>
                  <a
                    href="mailto:info@bansahandassociates.com"
                    className="infoContainer"
                  >
                    <BsEnvelope className="icon gold" />
                    <p> info@bansahandassociates.com</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="contacts">
                <div className="phone">
                  <a href="tel:+14167850522">
                    <p>+1 416-785-0522</p>
                  </a>
                </div>
                <div className="socials">
                  <Link className="icons">
                    <BsWhatsapp className="icon" />
                  </Link>
                  <Link className="icons">
                    <BsFacebook className="icon" />
                  </Link>
                  <Link className="icons">
                    <BsInstagram className="icon" />
                  </Link>
                </div>
              </div>
              <div className="menuHolder">
                <div className="permalinks">
                  <div className="header">
                    <h2>our services</h2>
                  </div>
                  <div className="menu">
                    {ourServiceLinks.map((links) => {
                      return (
                        <Link
                          className="service_menu_item"
                          key={links.id}
                          to={links.url}
                        >
                          {links.name}
                        </Link>
                      );
                    })}
                  </div>
                </div>

                <div className="search-col">
                  <div className="search-bar">
                    <form action="">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search here..."
                      />
                      <a className="searchButton" type="submit">
                        <FaSistrix className="searchIcon" />
                      </a>
                    </form>
                  </div>
                  <div className="text-summary">
                    <article>
                      Embark on your immigration journey with confidence.
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-menu">
            <div className="copyright">
              <div className="copy-name">
                &copy;
                {year} <Link to={"./"}> Bansah And Associates</Link> <br />
                <small>Designed & developed by </small> |
                <a
                  href="http://www.veltetech.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""} Velte Technology Solutions
                </a>
              </div>
            </div>
            <div className="menu-items">
              {navigationLinks.map((links) => {
                return (
                  <Link className="menu_item" key={links.id} to={links.url}>
                    {links.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
