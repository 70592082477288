import React from "react";
import "./service-card.css";
import CTAButton from "../../ui/CTAButton";

const ServiceCard = ({ heading, body, url }) => {
  return (
    <>
      <div id="service-card" className="card">
        <div className="head">
          <h4>{heading}</h4>
        </div>
        <p className="body">{body}</p>
        <CTAButton link={url} text="Get started" specialClass="goldBkg" />
      </div>
    </>
  );
};

export default ServiceCard;
