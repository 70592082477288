import React from "react";
import "../../styles.css";
import "./whatwedo.css";
// import iccrc from "../../assets/img/iccrc.png";
import { whatWeDo } from "../../data";

const WhatWeDoSection = () => {
  return (
    <section className="whatwedo">
      <div className="container">
        <div className="row">
          {/* what we do */}
          <div className="col-md-12 col-lg-5 my-auto py-5">
            <h3>What we Do</h3>
            <div className="subtitle">
              <h1>Our job is to provide legal help</h1>
            </div>

            <p className="mb-0">
              Mr. Edward Bansah had a notable career at the University of Ghana
              as an administrator before starting Bansah and Associates. His own
              immigration story, which began in the United States as a skilled
              worker and ended in November 1998 when he settled in Canada,
              inspires him to help others fulfill their Canadian aspirations. In
              addition to his expertise as an interpreter for the Immigration
              and Refugee.
            </p>
          </div>
          <div className="col-md-12 col-lg-7">
            {whatWeDo.map((item, index) => (
              <div
                key={item.title + index}
                className="row align-items-center mb-3"
              >
                <div className="col-md-2">
                  <div className={index != 0 ? "circle2" : "circle"}>
                    <img
                      src={item.icon}
                      className="img-fluid"
                      alt="what we do"
                    />
                  </div>
                </div>

                <div className="col-md-10 px-lg-0 px-3 mt-lg-0 mt-2">
                  <div className="row mt-2">
                    <div className="col-7 ">
                      <h5>{item.title}</h5>
                      <p className="mt-0 mb-4">{item.desc}</p>
                    </div>
                    <div className="col-5 counter mt-3">
                      <h2 className="">{item.count}+</h2>
                    </div>
                  </div>

                  <hr className="whatwedoline" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDoSection;
