import React from "react";
import "./useful-links.css";
import { Link } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";

const UsefulLinks = () => {
  return (
    <>
      <section className="usefulLinks">
        <div className="container">
          <div className="txtContent">
            <div className="header">
              <h3>for more information</h3>
              <p>
                Below you will find some links to external websites that provide
                information on the processing of employment-based immigration
                applications:
              </p>
            </div>
            <div className="content">
              <ul>
                <li className="linkItem">
                  <div className="title">USCIS Case Status Enquiries </div>
                  <article>
                    To find out the current status of a pending application or
                    petition, go to the United States Department of Homeland
                    Security's U.S. Citizenship and Immigration Services (USCIS)
                    online{" "}
                    <Link
                      target="_blank"
                      to={"https://egov.uscis.gov/casestatus/landing.do"}
                    >
                      case status database
                    </Link>
                    , and type in your receipt number, without the dashes.
                  </article>
                </li>

                <li className="linkItem">
                  <div className="title">USCIS Processing Times </div>
                  <article>
                    The USCIS also publishes{" "}
                    <Link
                      target="_blank"
                      to={"https://egov.uscis.gov/processing-times/"}
                    >
                      petition/application processing times
                    </Link>
                    , categorized by Service Center.
                  </article>
                </li>

                <li className="linkItem">
                  <div className="title">Visa Case Status Enquiries </div>
                  <article>
                    To find out the current status of a pending immigrant or
                    nonimmigrant visa application, go to the United States
                    Department of State's{" "}
                    <Link
                      target="_blank"
                      to={"https://ceac.state.gov/CEACStatTracker/Status.aspx"}
                    >
                      Consular Electronic Application Center
                    </Link>{" "}
                    and type in your case number.
                  </article>
                </li>

                <li className="linkItem">
                  <div className="title">Visa Priority Dates </div>
                  <article>
                    An immigrant visa is only available if the priority date is
                    shown as current under the{" "}
                    <Link
                      target="_blank"
                      to={
                        "https://travel.state.gov/content/travel/en/legal/visa-law0/visa-bulletin.html"
                      }
                    >
                      Visa Bulletin
                    </Link>
                    . Priority dates are determined by the date of filing of the
                    labor certification in employment-based cases or the
                    immigrant petition in family-based cases.
                  </article>
                </li>

                <li className="linkItem">
                  <div className="title">Consulate Waiting Times </div>
                  <article>
                    The Department of State has published{" "}
                    <Link
                      target="_blank"
                      to={
                        "https://travel.state.gov/content/travel/en/us-visas/visa-information-resources/wait-times.html"
                      }
                    >
                      Visa Wait Times
                    </Link>
                    , categorized by Consulate.
                  </article>
                </li>

                <li className="linkItem">
                  <div className="title">General USCIS Inquiries </div>
                  <article>
                    General questions regarding addresses, USCIS appointments,
                    status inquiries, corrections, filing fees, etc. can be
                    addressed to the USCIS{" "}
                    <Link
                      target="_blank"
                      to={"https://www.uscis.gov/about-us/contact-us"}
                    >
                      National Customer Service Center
                    </Link>{" "}
                    at (800) 375-5283.
                  </article>
                </li>

                <li className="linkItem">
                  <div className="title">Department of Labor FLAG Portal </div>
                  <article>
                    This{" "}
                    <Link target="_blank" to={"http://flag.dol.gov/"}>
                      website
                    </Link>{" "}
                    features the Department of Labor's Labor Condition
                    Application (LCA) and Permanent Labor Certification (PERM)
                    online filing systems. It also contains processing times,
                    prevailing wage information and immigration-related FAQs.
                  </article>
                </li>

                <li className="linkItem">
                  <div className="title">
                    Corporate Registrations and Filings{" "}
                  </div>
                  <article>
                    The websites of the{" "}
                    <Link
                      target="_blank"
                      to={"https://www.dos.ny.gov/corps/index.html"}
                    >
                      New York Department of State - Division of Corporations
                    </Link>{" "}
                    and the{" "}
                    <Link
                      target="_blank"
                      to={"https://www.njportal.com/dor/businessrecords/"}
                    >
                      New Jersey Department of Treasury
                    </Link>{" "}
                    contain guidance on corporate filings, name availability,
                    certifications, licensing requirements and other useful
                    information relating to business entities and their internal
                    governance.
                  </article>
                </li>

                <li className="linkItem">
                  <div className="title">Consular Information </div>
                  <article>
                    To find out the contact information (including email,
                    address and telephone) for the consulates/embassies for most
                    countries in the world, visit{" "}
                    <Link
                      target="_blank"
                      to={"https://www.embassy-worldwide.com/"}
                    >
                      this site
                    </Link>
                    .
                  </article>
                </li>
              </ul>

              <p>
                For further information regarding immigration law, visit the{" "}
                <Link target="_blank" to={"http://www.uscis.gov/"}>
                  USCIS Website
                </Link>{" "}
                The website of the Department of Labor, which has promulgated
                regulations relating to alien labor certification and labor
                condition applications, can be visited at{" "}
                <Link target="_blank" to={"http://www.doleta.gov"}>
                  doleta.gov
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default UsefulLinks;
