import React from "react";
import "../../styles.css";
import "./about-us.css";
import { ContactSection, ScrollToTop, WhatWeDoSection } from "../../components";
import { Link } from "react-router-dom";
import ceoLogo from "../../assets/img/BANSAH-CEO-LOGO.png";
import willsmith from "../../assets/willsmith.jpeg";

const AboutUs = () => {
  return (
    <>
      {/* ceo section */}
      <section className="ceo-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="ceo-bg">
                <img src={ceoLogo} className="ceo-img" alt="ceo" />
              </div>
            </div>

            <div className="col-md-6 mt-lg-0 mt-4">
              <h1>About US</h1>
              <div className="subtitle">
                <h2>Crafted Excellence in Legal Advocacy</h2>
              </div>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo
                expedita consectetur tempora odio inventore sit deleniti cum
                quam ipsum ipsa sint accusamus, repudiandae ut excepturi odit
                fugiat unde! Aliquam quasi quam dolores pariatur id accusamus
                facilis eum velit unde distinctio?
              </p>

              <Link to="/our-services" className="btn-blue">
                Our Services
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* our founder */}
      <section className="our-founder pt-lg-5 pt-2 pb-4 mt-4">
        <div className="container">
          <h1 className="mb-3">Our Founder</h1>

          <div className="row">
            <div className="col-md-6">
              <p>
                Mr. Edward Bansah had a notable career at the University of
                Ghana as an administrator before starting Bansah and Associates.
                His own immigration story, which began in the United States as a
                skilled worker and ended in November 1998 when he settled in
                Canada, inspires him to help others fulfill their Canadian
                aspirations. In addition to his expertise as an interpreter for
                the Immigration and Refugee Board, Edward has worked for
                Canadian Tire, a logistics company.Mr. Edward Bansah had a
                notable career at the University of Ghana as an administrator
                before starting Bansah and Associates. His own immigration
                story, which began in the United States as a skilled worker and
                ended in November 1998 when he settled in Canada, inspires him
                to help others fulfill their Canadian aspirations. In addition
                to his expertise as an interpreter for the Immigration and
                Refugee Board, Edward has worked for Canadian Tire, a logistics
                company.
              </p>
            </div>

            <div className="col-md-6">
              <p>
                Mr. Edward Bansah had a notable career at the University of
                Ghana as an administrator before starting Bansah and Associates.
                His own immigration story, which began in the United States as a
                skilled worker and ended in November 1998 when he settled in
                Canada, inspires him to help others fulfill their Canadian
                aspirations. In addition to his expertise as an interpreter for
                the Immigration and Refugee Board, Edward has worked for
                Canadian Tire, a logistics company.Mr. Edward Bansah had a
                notable career at the University of Ghana as an administrator
                before starting Bansah and Associates. His own immigration
                story, which began in the United States as a skilled worker and
                ended in November 1998 when he settled in Canada, inspires him
                to help others fulfill their Canadian aspirations. In addition
                to his expertise as an interpreter for the Immigration and
                Refugee Board, Edward has worked for Canadian Tire, a logistics
                company.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* our team */}
      <section className="our-team pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 pr-0">
              <h1>Our Team</h1>
            </div>
            <div className="col-md-9 pl-lg-0 pl-auto">
              <p className="mb-0">
                Mr. Edward Bansah had a notable career at the University of
                Ghana as an administrator before starting Bansah and Associates.
                His own immigration story, which began in the United States as a
                skilled worker and ended in November 1998 when he settled in
                Canada, inspires him to help others fulfill their Canadian
                aspirations. In addition to his expertise as an interpreter for
                the Immigration and Refugee
              </p>
            </div>
          </div>

          <div className="row mt-5 justify-content-center">
            {/* array of 3 map */}
            {Array(3)
              .fill()
              .map((_, i) => (
                <div className="col-md-6 col-lg-4 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <img
                        src={willsmith}
                        className="img-fluid"
                        alt="team photo"
                      />
                    </div>
                    <div className="card-foot">
                      <h4>Will Smith</h4>
                      <p className="mb-2 mt-0">CEO</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <WhatWeDoSection />

      <ContactSection />

      <ScrollToTop />
    </>
  );
};

export default AboutUs;
