import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import {
  Home,
  ServicePage,
  NotFound,
  Portfolio,
  AboutUs,
  ContactPage,
  WebDevForms,
  BootCamp,
  Enroll,
  BlenderCourse,
  GraphicDesign,
  MobileDevelopment,
  WebDevelopment,
  UiUx,
  DataAnalytics,
  FrontEndWebDevelopment,
  FaqPage,
  UsefulLinks,
  OurServices,
  Booking,
} from "./pages";

import Layout from "./Layouts/Layout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index exact element={<Home />} />
      <Route path="/booking" element={<Booking />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/our-services" element={<OurServices />} />
      <Route path="/useful-links" element={<UsefulLinks />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
