import "./results.css";

const Results = ({ data }) => {
  return (
    <>
      <div className="results" id="general">
        <ul>
          {data.map((item) => (
            <li key={item.id} id={item.sectionID}>
              <div className="content">
                <div className="contentHeader">
                  <h3>{item.title}</h3>
                </div>
                <div className="contentTxt">
                  <article>{item.details}</article>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Results;
