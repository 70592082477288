import React from "react";
import { InlineWidget } from "react-calendly";
import "../../styles.css";

const Booking = () => {
  return (
    <section className="container py-5">
      <InlineWidget url="https://calendly.com/bansahassociates/immigration-consultation" />
    </section>
  );
};

export default Booking;
